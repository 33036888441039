@import url('https://fonts.googleapis.com/css2?family=Rubik+Mono+One&display=swap');

* {
  margin: 0;
  padding: 0;

  box-sizing: border-box;
  color: rgb(240, 240, 240);

  user-select: none;
  font-family: monospace;
}

html, body {
  background-color: black;
}

html, body, #root {
  height: 100%;
}

h1 {
  font-family: "Rubik Mono One", monospace;
  font-weight: 400;
  font-style: normal;
}

p {
  font-weight: bold;
}